import { TicketsContext } from 'App'
import { useContext } from 'react'
import { matchRoutes, useLocation } from 'react-router-dom'
import destinations, { Routes } from 'utilities/destinations'

type PreviousStep = {
  showBackButton: true
  prevDestination: string
} | {
  showBackButton: false
  prevDestination?: never
}

const usePreviousStep = (): PreviousStep => {
  const location = useLocation()
  const matches = matchRoutes([
    { path: Routes.parkingActivate },
    { path: Routes.parkingActivateForce },
    { path: Routes.parkingReceipt }
  ], location)
  const isActivate = matchesRoute(Routes.parkingActivate)
  const isForceActivate = matchesRoute(Routes.parkingActivateForce)
  const isReceipt = matchesRoute(Routes.parkingReceipt)
  const tickets = useContext(TicketsContext).tickets

  if (isActivate && tickets.length > 1) {
    return {
      showBackButton: true,
      prevDestination: destinations.parking.route()
    }
  } else if (isForceActivate && isForceActivate.params.ticketID) {
    return {
      showBackButton: true,
      prevDestination: destinations.parking.activate(isForceActivate.params.ticketID).route()
    }
  } else if (isReceipt && tickets.length > 1) {
    return {
      showBackButton: true,
      prevDestination: destinations.parking.route()
    }
  }

  return { showBackButton: false }

  function matchesRoute(route: Routes) {
    return matches?.find(m => m.route.path === route && m.pathname === location.pathname)
  }
}

export default usePreviousStep