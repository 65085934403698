import React, { PropsWithChildren } from 'react'
import ErrorFallback from './ErrorFallback'
import * as Sentry from '@sentry/react'

class ErrorBoundary extends React.Component<PropsWithChildren> {
  state = {
    error: null,
    errorInfo: null
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException(error)
    console.error(error, errorInfo)
  }

  render() {
    const content = this.state.error
      ? <ErrorFallback error={this.state.error}/>
      : this.props.children
    return <>{content}</>
  }
}

export default ErrorBoundary