import React, { ReactElement } from 'react'
import SupportSection from 'globalComponents/SupportSection'
import styles from './ErrorFallback.module.css'
import _ from 'lodash'
import { Logo } from 'globalComponents/Logo'

const ErrorFallback: React.FC<{error: any}> = ({ error }) => {
  return (
    <div className={styles.errorFallbackContainer}>
      <Logo size='small' hideIfNoLogo/>
      <h1>Prepaid Activation Service is Unavailable at this time.</h1>
      <div className={styles.error}>
        { fallbackErrorTitle() }
        { fallbackErrorBody() }
      </div>
      <SupportSection/>
    </div>
  )

  function fallbackErrorTitle() {
    const defaultErrorTitle = error.name === 'Error'
      ? <h2>Something went wrong.</h2>
      : <h2>{error.name}</h2>
    if (!error?.response?.status) return defaultErrorTitle
    switch (error.response.status) {
      case 401:
        return <h2>Unauthorized</h2>
      case 403:
        return <h2>Forbidden</h2>
      default:
        return defaultErrorTitle
    }
  }

  function fallbackErrorBody() {
    if (!error) { return <p>An unknown error occurred</p> }
    return messageForError(error)
  }
}

export function messageForError(obj: any): ReactElement {
  if (obj instanceof TypeError && obj.message === 'Failed to fetch') {
    return <p>Your Internet connection appears to be offline. Please check cell or wifi connection.</p>
  }

  if (React.isValidElement(obj)) {
    return obj
  }

  let msg =
    obj.message ||
    obj.json && (obj.json.reason || obj.json.message) ||
    obj.reason ||
    `${obj}`

  // Adding punctuation to the end incase we forget it 
  if (!msg.endsWith('.') && !msg.endsWith('!') && !msg.endsWith('?')) msg += '.'
  return (
    <p
      style={{ whiteSpace: 'pre-wrap' }}
      dangerouslySetInnerHTML={{ __html: msg }}
    />
  )
}

export default ErrorFallback