import _ from 'lodash'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Routes } from 'utilities/destinations'

const useRootBackgroundColor = () => {
  const location = useLocation() // This hook returns the location object that represents the current URL

  useEffect(() => {
    const root = document.getElementById('root')
    if (!root) return

    const routesWithNeutralBG = [Routes.landing, Routes.parking]
    const shouldUseNeutralBG = _.includes(routesWithNeutralBG, location.pathname)

    if (shouldUseNeutralBG) {
      root.classList.remove('secondary-bg')
      root.classList.add('neutral-bg')
    } else {
      root.classList.remove('neutral-bg')
      root.classList.add('secondary-bg')
    }
  }, [location]) // This effect runs only when the location changes
}

export default useRootBackgroundColor