import React from 'react'
import { Link } from 'react-router-dom'
import destinations from 'utilities/destinations'

// TODO add some kind of copy to redirect to them the MLB app
const Landing: React.FC = () => {
  return (
    <Link to={destinations.parking.route()}>Take me to my passes</Link>
  )
}

export default Landing
