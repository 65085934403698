import { BASE_API_URL } from './config'
import * as Sentry from '@sentry/react'

type ResponseFormat = 'json' | 'blob' | 'text'

class FetchError extends Error {
  constructor(msg: string, rsp: Response, json: any) {
    super(msg)
    this.response = rsp
    this.json = json
    Object.setPrototypeOf(this, FetchError.prototype) // https://stackoverflow.com/a/41429145/6444
  }

  response: Response
  json: any
}

async function go<T>(url: string, options: RequestInit = {}, responseType: ResponseFormat = 'json') {
  const headers = new Headers({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  })


  const token = localStorage.getItem('mlbSessionToken') || window.authToken || ''
  // eslint-disable-next-line no-console
  console.log(`ls: ${localStorage.getItem('mlbSessionToken')},  win: ${window.authToken}`)
  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  const defaultOptions: RequestInit = {
    headers,
    cache: 'no-cache'
  }

  const finalOptions: RequestInit = { ...defaultOptions, ...options }

  try {
    const rsp = await fetch(`${BASE_API_URL}${url}`, finalOptions)
    if (!rsp.ok) {
      // log token to Sentry if 401
      if (rsp.status === 401) {
        Sentry.captureMessage('401 Unauthorized', {
          extra: {
            token: localStorage.getItem('mlbSessionToken'),
            windowAuthToken: window.authToken
          }
        })
      }
      throw new Error(`HTTP error! Status: ${rsp.status}`)
    }

    switch (responseType) {
      case 'blob':
        return await rsp.blob() as unknown as T
      case 'text':
        return await rsp.text() as unknown as T
      default:
        return await rsp.json() as T
    }
  } catch (error: unknown) {
    if (error instanceof FetchError) {
      console.error('FetchError:', error.message, 'Response:', error.response)
      throw error
    } else if (error instanceof Error) {
      console.error('Error:', error.message)
      throw error
    } else {
      console.error('Unknown error type:', error)
      throw new Error('Unknown error occurred')
    }
  }
}

export async function get<T>(url: string, options: RequestInit = {}): Promise<T> {
  return await go<T>(url, { ...options, method: 'GET' })
}

export async function post<T>(url: string, data?: any, options: RequestInit = {}): Promise<T> {
  return await go<T>(url, {
    ...options,
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export async function put<T>(url: string, data: any, options: RequestInit = {}): Promise<T> {
  return await go<T>(url, {
    ...options,
    method: 'PUT',
    body: JSON.stringify(data)
  })
}