import React from 'react'
import { Link } from 'react-aria-components'
import { useLocalStorage } from 'react-use'
import LocalStorageKeys from 'utilities/LocalStorageKeys'
import { normalizePhoneNumber } from 'utilities/etc'
import { MLBSkinMini } from 'utilities/types'
import { ReactComponent as Phone } from 'assets/icons/phone.svg'
import { ReactComponent as SupportPerson } from 'assets/icons/support-person.svg'
import { ReactComponent as Mail } from 'assets/icons/mail.svg'
import styles from './SupportSection.module.css'

const SupportSection: React.FC = () => {

  const [skin] = useLocalStorage<MLBSkinMini | undefined>(LocalStorageKeys.skin)
  const supportPhone = skin?.supportPhone || '4144316555'
  const supportEmail = skin?.supportEmail || 'info@interstateparking.com'

  return (
    <div className={styles.supportSectionContainer}>
      <div>
        <SupportPerson className={styles.filledIcon}/>
        <p>For immediate assistance please locate a parking ambassador</p>
      </div>
      <div>
        <Phone className={styles.strokedIcon}/>
        <p>Or call <Link style={{ whiteSpace: 'nowrap' }} href={`tel:${supportPhone}`}>{ normalizePhoneNumber(supportPhone) }</Link></p>
      </div>
      <div>
        <Mail className={styles.strokedIcon}/>
        <p>Or email <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link></p>
      </div>
    </div>
  )
}

export default SupportSection