import React, { useState } from 'react'
import { Button, DialogTrigger } from 'react-aria-components'
import styles from './FloatingHelpButton.module.css'
import { classNames } from 'utilities/etc'
import SupportSection from './SupportSection'
import { TTModal } from './TTModal'
import { ReactComponent as Question } from 'assets/icons/question.svg'

const FloatingHelpButton: React.FC = () => {
  const [fabPressed, setFabPressed] = useState(false)

  return (
    <DialogTrigger>
      <Button
        className={classNames(styles.fab, fabPressed && styles.fabPressed)}
        onPressStart={() => setFabPressed(true)}
        onPressEnd={() => setFabPressed(false)}
      ><Question/></Button>
      <TTModal title='Help'>
        <SupportSection/>
      </TTModal>
    </DialogTrigger>
  )
}

export default FloatingHelpButton