import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import destinations from 'utilities/destinations'
import { Logo } from './Logo'
import { Button } from 'react-aria-components'
import usePreviousStep from 'utilities/hooks/usePreviousStep()'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'

const Navigation: React.FC = () => {
  const navigate = useNavigate()
  const prev = usePreviousStep()

  return (
    <header>
      { prev.showBackButton &&
        <Button onPress={() => navigate(prev.prevDestination, { replace: true })}>
          <ArrowLeft/>
          <span>Back</span>
        </Button>
      }
      <Link to={destinations.parking.route()}><Logo size='small'/></Link>
    </header>
  )
}

export default Navigation