import _ from 'lodash'
import { $enum } from 'ts-enum-util'
import * as Sentry from '@sentry/react'

export enum ActivateFailReason {
  alreadyPaid = 'alreadyPaid', // License plate used for a different pass
  invalidPlateOrState = 'invalidPlateOrState',
  invalidTicket = 'invalidTicket',
  missingMLBSession = 'missingMLBSession',
  missingWebSession = 'missingWebSession',
  missingTDCPriceScale = 'missingTDCPriceScale',
  missingParkingSessionLotInfo = 'missingParkingSessionLotInfo',
  missingValidZonesForTicket = 'missingValidZonesForTicket',
  sessionInViolation = 'sessionInViolation',
  rateLotMismatch = 'rateLotMismatch',
  ticketRedeemed = 'ticketRedeemed',
  unknownSystemError = 'unknownSystemError',
  upgradeRequired = 'upgradeRequired'
}

type Redirect = {
  path: string
  title: string
}
export class GenericErrorWithRedirect extends Error {
  redirect?: Redirect

  constructor(name: string, message: string, redirect?: Redirect) {
    super(message)
    this.redirect = redirect
    this.name = name
  }
}
export class ActivateError extends GenericErrorWithRedirect {
  reason: ActivateFailReason

  constructor(reason: ActivateFailReason, barcodeNum?: string, redirect?: Redirect) {
    const key = $enum(ActivateFailReason).getKeyOrThrow(reason)
    super(errorName(), errorMessage(), redirect)
    this.reason = reason

    function errorMessage() {
      const systemErrorCopy = 'A system error occurred. Please try again. If the issue persists try restarting the app or contacting support.'
      switch (reason) {
        case ActivateFailReason.alreadyPaid:
          return 'A parking pass for this vehicle has already been activated for today’s event'
        case ActivateFailReason.invalidPlateOrState:
          return 'Invalid license plate number. Please confirm that your information is correct and try again'
        case ActivateFailReason.invalidTicket: {
          const extra = 'Activation failed. Please press the button below to go back to your passes.'
          return barcodeNum ?
            `Unable to find the parking pass with number: ${barcodeNum}. ${extra}`
            : `Unable to find the submitted parking pass. ${extra}`
        }
        case ActivateFailReason.ticketRedeemed:
          return 'This parking pass has already been redeemed.'
        case ActivateFailReason.missingMLBSession:
          return 'Your session has expired. Please close this page and start over.'
        case ActivateFailReason.missingParkingSessionLotInfo:
          return [systemErrorCopy, 'Error code: 4002'].join('. ')
        case ActivateFailReason.missingTDCPriceScale:
          return [systemErrorCopy, 'Error code: 4003'].join('. ')
        case ActivateFailReason.missingValidZonesForTicket:
          return [systemErrorCopy, 'Error code: 4004'].join('. ')
        case ActivateFailReason.missingWebSession:
          return [systemErrorCopy, 'Error code: 4005'].join('. ')
        case ActivateFailReason.sessionInViolation:
          return 'This plate is currently in violation status. Parking activation is no longer available.'
        case ActivateFailReason.unknownSystemError:
          return 'Unknown system error. Please try again. If the issue persists try restarting the app or contacting support.'
        // This shouldn't be shown since we get upgradeInfo in the response and redirect them to the 
        case ActivateFailReason.rateLotMismatch:
        case ActivateFailReason.upgradeRequired: {
          console.error('This is a business logic bug: throwing an error for "rateLotMismatch" or "upgradeRequired" without "upgradeInfo" present.')
          return 'It looks like you’re trying to checkout with a pass reserved a different zone. Please relocate.'
        }
        default: {
          const e = new Error(`State machine error: unhandled ActivateFailReason.${key}`)
          Sentry.captureException(e)
          console.error(e)
          return [systemErrorCopy, _.upperFirst(_.lowerCase(_.startCase(key)))].join('. ')
        }
      }
    }

    function errorName() {
      switch (reason) {
        case ActivateFailReason.invalidTicket:
          return 'Unable to Find Your Parking Pass'
        default:
          return 'Parking Pass Activation Failed'
      }
    }
  }
}

// Not returned by API, FE throws it depending on the result of the API call
export enum ReceiptFailReason {
  invalidTicket,
  notFound
}

export class ReceiptError extends GenericErrorWithRedirect {
  reason: ReceiptFailReason

  constructor(reason: ReceiptFailReason, barcodeNum?: string, redirect?: Redirect) {
    super('Receipt Error', errorMessage(), redirect)
    this.reason = reason

    function errorMessage() {
      switch (reason) {
        case ReceiptFailReason.invalidTicket:
          return barcodeNum ? `Pass: ${barcodeNum} not found.`: 'Ticket not found.'
        case ReceiptFailReason.notFound:
          return barcodeNum ? `No receipt found for ${barcodeNum}.` : 'No receipt found.'
      }
    }
  }
}