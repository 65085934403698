import React from 'react'
import ReactDOM from 'react-dom/client'
import 'intl-list-format' // For < iOS 14.5
import 'intl-list-format/locale-data/en' // For < iOS 14.5
import './index.css'
import 'globalComponents/TTModal.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from 'globalComponents/errors/ErrorBoundary'


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
)
