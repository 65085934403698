import React, { PropsWithChildren } from 'react'
import { RadioGroup, RadioGroupProps, Text } from 'react-aria-components'
import { Control, Path, useController } from 'react-hook-form'

interface Props<T extends object> extends RadioGroupProps {
  control: Control<T>
  name: Path<T>,
  rules?: Record<string, unknown>
}

const TTRadio = <T extends object>(props: PropsWithChildren<Props<T>>): JSX.Element => {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules
  })

  return (
    <RadioGroup {...props} onChange={handleChange} value={value || ''}>
      { props.children }
      { error ? <Text slot='errorMessage' className='react-aria-FieldError'>{error.message}</Text> : <></> }
    </RadioGroup>
  )

  function handleChange(value: string) {
    onChange(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }
}

export default TTRadio