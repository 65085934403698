import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TTLinkButton from 'globalComponents/fields/TTLinkButton'
import TTButton from 'globalComponents/fields/TTButton'
import * as api from 'utilities/api'
import destinations from 'utilities/destinations'
import _ from 'lodash'
import TTRadio from 'globalComponents/fields/TTRadio'
import { Radio } from 'react-aria-components'
import { ReactComponent as Alert } from 'assets/icons/alert.svg'
import { ReactComponent as Motorcycle } from 'assets/icons/motorcycle.svg'
import { ReactComponent as Accessibile } from 'assets/icons/accessible.svg'
import { GenericErrorWithRedirect } from 'utilities/ExpectedErrors'
import usePreviousStep from 'utilities/hooks/usePreviousStep()'
import useErrorModal from 'utilities/hooks/useErrorModal()'
import styles from './index.module.css'
import { ActivatePayload, ForcedCheckoutReason } from 'utilities/types'
import TTLoader from 'globalComponents/TTLoader'
import * as Sentry from '@sentry/react'
import { TTModal } from 'globalComponents/TTModal'
import { useToggle } from 'react-use'

interface Inputs {
  reason?: ForcedCheckoutReason
}

const ParkingActivateForce: React.FC = () => {
  const navigate = useNavigate()
  const { barcodeNum } = useParams()
  const [query] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [confirmationModalOpen, toggleConfirmationModal] = useToggle(false)
  const { handleSubmit, control, watch } = useForm<Inputs>({ defaultValues: { reason: undefined } })
  const prev = usePreviousStep()
  const showErrorModal = useErrorModal()

  const parkedInLotName = query.get('parkedInLotName') || undefined
  const passIsForLotName = query.get('passIsForLotName') || undefined
  const plate = query.get('plate') || undefined

  const forceCheckoutReason = watch('reason')
  const adaReasonNotice = 'Please ensure your ADA placard is properly displayed and visible to avoid receiving a violation.'
  const incorrectDetectionReasonNotice = 'You acknowledge the risk of receiving a parking violation if you park in a lot where this pass is not valid.'
  const motorcycleReasonNotice = 'Please limit motorcycles to two per stall. Payment is required for each motorcycle. Parking in an incorrect location or paying the incorrect rate will result in fines and/or the vehicle being towed at the owner’s expense.'

  const onSubmit: SubmitHandler<Inputs> = () => {
    toggleConfirmationModal()
  }

  return (
    <>
      { loading ? <TTLoader/> : <></> }
      <TTModal
        title='Notice'
        titleStyle='caution'
        isOpen={confirmationModalOpen}
        onOpenChange={toggleConfirmationModal}
        isDisabled={loading}
        footerButton={{
          copy: 'Activate Pass',
          action: () => submit({ reason: forceCheckoutReason })
        }}
      >
        { forceCheckoutReason === ForcedCheckoutReason.ada
          ? adaReasonNotice
          : forceCheckoutReason === ForcedCheckoutReason.parkedHere
            ? incorrectDetectionReasonNotice
            : motorcycleReasonNotice
        }
      </TTModal>
      <section className={styles.forceActivationSection}>
        <h1> <Alert/> Alert</h1>
        <h2>Lot Mismatch Detected</h2>
        { explainDetection() }
        <p>If you are confident that you are in the correct location for your pass, you may still proceed with the checkout process by selecting one of the options below:</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={loading}>
            <TTRadio<Inputs>
              control={control}
              name='reason'
              rules={{ required: 'Please select a reason or cancel and relocate your car.' }}
              aria-label='Select an option or cancel activation'

            >
              <Radio value={ForcedCheckoutReason.ada}>
                <Accessibile/>
                <div>I have an ADA placard</div>
              </Radio>
              <Radio value={ForcedCheckoutReason.parkedHere}>
                <div>
                  { passIsForLotName
                    ? <>I am parked in the <strong>{_.upperCase(passIsForLotName)}</strong></>
                    : <>I am parked in the correct parking lot.</>
                  }
                </div>
              </Radio>
              <Radio value={ForcedCheckoutReason.motorcycle}>
                <Motorcycle/>
                <div>I have a motorcycle</div>
              </Radio>
            </TTRadio>
            <div className={styles.buttonContainer}>
              <TTButton buttonType='primary' type='submit'>Activate anyways</TTButton>
              <TTLinkButton buttonType='secondary' href={destinations.parking.route()}>Cancel and relocate</TTLinkButton>
            </div>
          </fieldset>
        </form>
      </section>
    </>
  )
  function explainDetection() {
    if (parkedInLotName && passIsForLotName) {
      return <p>It looks like you’re trying to activate a pass for the <strong>{_.upperCase(passIsForLotName)}</strong> but our systems indicate that your vehicle may be located in the <strong>{_.upperCase(parkedInLotName)}.</strong></p>
    }
    return <p>Your car appears to be in a different lot than the pass you are attempting to activate is for.</p>
  }

  async function submit(data: Inputs) {
    try {
      setLoading(true)

      if (!barcodeNum) throw new Error('Ticket information not found')
      // Shouldn't happen, but then again no errors should happen :/
      if (!plate) {
        throw new GenericErrorWithRedirect(
          'Something went wrong',
          'License plate information not found. Please go back to the previous step and try again',
          { path: prev.prevDestination || destinations.parking.route(), title: 'Start over' }
        )
      }
      if (!data.reason) throw Error('You must select a reason to continue')


      const payload: ActivatePayload = {
        plate,
        barcode: barcodeNum,
        forceCheckout: true,
        forceReason: data.reason
      }

      const response = await api.v1.activate(payload)
      if (response.success) {
        navigate(destinations.parking.activate(barcodeNum!).completed.route()) // Safe bang
      } else {
        throw new Error('Force checkout unsuccessful, please try again')
      }
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error)
        showErrorModal(error)
      } else {
        console.error(error)
      }
    } finally {
      setLoading(false)
    }
  }
}

export default ParkingActivateForce