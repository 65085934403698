import { createSearchParams } from 'react-router-dom'
import LocalStorageKeys from './LocalStorageKeys'

export enum Routes {
  parkingActivateForce = '/parking/:barcodeNum/activate/wrong-zone',
  parkingActivateCompleted = '/parking/:barcodeNum/activate/completed',
  parkingActivate = '/parking/:barcodeNum/activate',
  parkingReceipt = '/parking/:barcodeNum/confirmation',
  parking = '/parking',

  landing = '/' // MUST BE LAST!
}

const destinations = {
  landing: { route: () => replaceParamater(Routes.landing, undefined) },
  parking: {
    route: () => replaceParamater(Routes.parking, undefined),
    activate: (barcodeNum: string) => ({
      route: () => replaceParamater(Routes.parkingActivate, { barcodeNum }),
      completed: { route: () => replaceParamater(Routes.parkingActivateCompleted, { barcodeNum } ) },
      force: { route: (query: Record<string, string>) => replaceParamater(Routes.parkingActivateForce, { barcodeNum }, query) }
    }),
    confirmation: (barcodeNum: string) => ({
      route: () => replaceParamater(Routes.parkingReceipt, { barcodeNum })
    })
  }
}

function replaceParamater(destination: Routes, params: Record<string, any> | undefined, query?: Record<string, string>) {
  let route: string = destination

  const token = localStorage.getItem(LocalStorageKeys.mlbSessionToken) || window.authToken || ''
  const q = query || {}
  if (token) q.token = token

  if (params) {
    route = Object.keys(params).reduce(
      (acc, key) => acc.replace(`:${key}`, params[key]),
      destination
    )
  }

  route += `?${createSearchParams({ ...q }).toString()}`

  return route
}

export default destinations
